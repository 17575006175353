import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { useMutation, useQuery } from '@apollo/client'
import { debounce } from 'lodash'
import { validate as isUuid } from 'uuid'

import notifier from 'tools/Notify'
import { getIn, putIn } from 'utils/dict'
import * as dict from 'utils/dict'
import {
  appendQueryParams,
  getLocalData,
  isGoodGqlVariable,
  removeQueryParams,
  setLocalData,
  setQueryParam,
  toggleQueryParam
} from 'utils/dom'
import { handleOneResponse } from 'utils/response'
import { getAccessToken } from 'utils/signon'
import { userToActiveOrgId } from 'utils/user'

//import { intcmp } from 'utils/string'
//import { enrichPosixTime } from 'utils/time'
import { LOAD_USER, UPDATE_USER, USER_SETTINGS } from 'common/User/graphql'
import { fallbackOrg, mergeSettings } from 'common/User/normalize'
import { updateSettingsDebounced } from 'common/User/save'
import GlobalContext, {
  R_GLOBAL //, assertActiveOrg
} from 'reducer/global'

// export function missingDeps(deps) {
//   return !Array.isArray(deps)
// }

export function usePage(pageData, deps) {
  // if (missingDeps(deps)) {
  //   console.trace('usePage missing deps')
  //   deps = []
  // }
  const [, dispatch] = useContext(GlobalContext)
  useEffect(() => {
    dispatch({
      type: R_GLOBAL.PAGE_SET,
      value: pageData
    })
  }, [dispatch, ...deps]) // eslint-disable-line react-hooks/exhaustive-deps
}

////////////////////////////////////////////////////////////////////////////////
export function useStateDebounce(value, wait = 300) {
  const [match, setMatch] = useState(value)
  const onInput = useMemo(() => debounce(setMatch, wait), [setMatch, wait])
  return [match, onInput]
}

export function useSearchQuery(QUERY, options = {}, wait = 300) {
  const { variables = {} } = options
  const [match, onInput] = useStateDebounce('', wait)

  const result = useQuery(QUERY, {
    ...options,
    variables: {
      ...variables,
      matching: match
    }
  })

  return { ...result, onInput }
}
// returns URLSearchParams object with the current query parameters
export function useQueryParams() {
  const location = useLocation()
  return useMemo(() => new URLSearchParams(location.search), [location])
}

const defaultToData = (params, meta) => ({})
function innerFilter(params, save, toData = defaultToData, meta = undefined) {
  const data = dict.filter(toData(params, meta), isGoodGqlVariable)
  return {
    get: params.get.bind(params),
    getAll: params.getAll.bind(params),
    has: params.has.bind(params),
    size: params.size,
    set(name, value) {
      const qp = setQueryParam(params, name, value)
      save(qp)
    },
    addAll(keyvals) {
      const qp = appendQueryParams(params, keyvals)
      save(qp)
    },
    remove(names) {
      const qp = removeQueryParams(params, names)
      save(qp)
    },
    clear() {
      save(new URLSearchParams())
    },
    toggle(name, value = undefined) {
      const qp = toggleQueryParam(params, name, value)
      save(qp)
    },
    data
  }
}

export function useSearchParamFilters(toData = defaultToData, meta = undefined) {
  const location = useLocation()
  const history = useHistory()
  const filter = useMemo(() => {
    const params = new URLSearchParams(location.search)
    const url = location.pathname + '?'
    const save = (qp) => {
      const to = url + qp.toString()
      history.push(to)
    }

    return innerFilter(params, save, toData, meta)
  }, [history, location, toData, meta])
  return filter
}

export function useSettingsFilter(
  namespace,
  toData = defaultToData,
  meta = undefined
) {
  const [changeUser] = useMutation(UPDATE_USER)
  const { settings, setSettings, userId } = useSettings()

  const filter = useMemo(() => {
    const path = namespace.split('/').concat('filter')
    const db = getIn(settings, path) ?? []
    const params = new URLSearchParams(db)

    const save = (qp) => {
      const stg = putIn({ ...settings }, path, Array.from(qp))
      setSettings(stg)
      updateSettingsDebounced(changeUser, userId, stg)
    }

    return innerFilter(params, save, toData, meta)
  }, [toData, meta, changeUser, namespace, settings, userId, setSettings])
  return filter
}

export function useLocalStorageFilter(
  namespace,
  toData = defaultToData,
  meta = undefined
) {
  const getFilter = useCallback(() => {
    const storage = getLocalData(namespace) ?? []
    const params = new URLSearchParams(storage)

    const save = (qp) => {
      setLocalData(namespace, Array.from(qp))
      window.dispatchEvent(new Event('localStorageChange'))
    }

    return innerFilter(params, save, toData, meta)
  }, [namespace, toData, meta])

  const [filter, setFilter] = useState(getFilter)

  useEffect(() => {
    const onChange = () => setFilter(getFilter())
    window.addEventListener('localStorageChange', onChange)
    return () => {
      window.removeEventListener('localStorageChange', onChange)
    }
  }, [setFilter, getFilter])

  return filter
}

export function useUrlFragment() {
  const location = useLocation()
  return location.hash.replace('#', '')
}

export function useFeature(flag) {
  const qp = useQueryParams()
  return qp.getAll('flag').includes(flag)
}

export function useOnClickOutside(parent, onClickOutside) {
  useEffect(() => {
    const onClick = ({ target }) => {
      if (parent == null) return null
      if (parent.contains(target) === false) {
        onClickOutside(target)
      }
    }
    document.addEventListener('click', onClick)
    return () => {
      document.removeEventListener('click', onClick)
    }
  }, [parent, onClickOutside])

  return null
}

export function useSettings() {
  const [
    {
      user: { id: userId }
    }
  ] = useContext(GlobalContext)
  const [settings, setSettings] = useState({})

  useQuery(USER_SETTINGS, {
    variables: { id: userId },
    onCompleted({ users }) {
      const settings = users?.results?.[0]?.settings
      if (settings) {
        setSettings(settings)
      }
    }
  })

  return { settings, setSettings, userId }
}

////////////////////////////////////////////////////////////////////////////////
export function windowPreferredTheme() {
  return window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'theme-dark'
    : 'theme-light'
}

export function isThemeDark() {
  return document.body.classList.contains('theme-dark')
}

export function useTheme() {
  // const [state, dispatch] = useContext(GlobalContext)
  //
  // useEffect(() => {
  //   if (window.matchMedia) {
  //     if (window.matchMedia('(prefers-color-scheme: light)').matches) {
  //       dispatch({ type: R_GLOBAL.PAGE_SET, value: { theme: 'theme-light' } })
  //     }
  //     window
  //       .matchMedia('(prefers-color-scheme: dark)')
  //       .addEventListener('change', (e) => {
  //         dispatch({
  //           type: R_GLOBAL.PAGE_SET,
  //           value: { theme: e.matches ? 'theme-dark' : 'theme-light' }
  //         })
  //       })
  //   }
  // }, [dispatch])
  //
  // const theme =
  //   state.page.theme || state.user.settings.theme || windowPreferredTheme()

  useEffect(() => {
    document.body.classList.remove('theme-dark')
    document.body.classList.remove('theme-light')
    document.body.classList.add('theme-light')
  }, [])
  // }, [theme])
}

////////////////////////////////////////////////////////////////////////////////
export function useSignon() {
  const [state, dispatch] = useContext(GlobalContext)

  // initialize apollo
  useEffect(() => {
    dispatch({ type: R_GLOBAL.APOLLO_RESET, dispatch })
  }, [dispatch])

  // Load user, refetch when we are able to do so (have a validation token)
  const { reload, refresh } = state.signon
  useEffect(() => {
    if (refresh) {
      getAccessToken(dispatch)
    }
  }, [refresh, dispatch])

  // Fetch profile after done signing in or if `reload` changes
  useEffect(() => {
    let isMounted = true
    if (reload) {
      state.apollo
        .query({
          query: LOAD_USER,
          variables: { id: 'self' },
          fetchPolicy: 'network-only'
        })
        .then(handleOneResponse('data', 'users'))
        .then(([self]) => {
          if (!isMounted) return null

          dispatch({ type: R_GLOBAL.USER_SET, value: self }) // first things first: set user

          const activeOrgId = userToActiveOrgId(self)
          const fallbackOrgId = fallbackOrg(self)?.id

          if (!isUuid(activeOrgId) && isUuid(fallbackOrgId)) {
            const settings = mergeSettings(self.settings, {
              activeOrgId: fallbackOrgId
            })
            return state.apollo
              .mutate({
                mutation: UPDATE_USER,
                variables: {
                  id: self.id,
                  user: {
                    settings: JSON.stringify(settings)
                  }
                }
              })
              .then(handleOneResponse('data', 'updateUser'))
              .then((self) => {
                dispatch({ type: R_GLOBAL.USER_SET, value: self })
              })
          }
        })
        .catch(notifier.onCatch(dispatch))
    }
    return () => {
      isMounted = false
    }
  }, [reload, state.apollo, dispatch])
}
