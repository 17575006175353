// import { useContext, useEffect } from 'react'

// import { invert } from 'utils/color'

// import GlobalContext from 'reducer/global'

// https://stackoverflow.com/questions/31677451/how-to-select-div-text-on-button-click
// export function selectElem(ref) {
//   const elem = ref?.current
//   if (elem) {
//     const range = document.createRange()
//     range.selectNode(elem)
//     window.getSelection().empty()
//     window.getSelection().addRange(range)
//   }
// }

export function onReturn(fxn) {
  return (event) => {
    if (event.key === 'Enter') fxn(event)
  }
}

// export function colorCorrect(hexColor, base = 'theme-dark') {
//   return document.body.classList.contains(base) ? hexColor : invert(hexColor)
// }

// export function TrackTheme() {
//   const [state] = useContext(GlobalContext)
//   const theme = `theme-${state.page.theme || state.user.settings.theme || 'dark'}`
//   useEffect(() => {
//     document.body.classList.remove('theme-dark')
//     document.body.classList.remove('theme-light')
//     document.body.classList.add(theme)
//   }, [theme])
//   return null
// }

export function anythingIsSelected() {
  const selection = document.getSelection()
  return ['forward', 'backward'].includes(selection.direction)
}

/*
 * @doctests
 *
 * ```js
 * t.truthy(isJson('{}'))
 * t.truthy(isJson('{"cat":{"hat":"tin"}}'))
 * t.falsy(isJson('{hi there}'))
 * ```
 */
export function isJson(str) {
  try {
    JSON.parse(str)
    return true
  } catch (err) {
    return false
  }
}

const VERSION = 'cato.v1'
function makeKey(key) {
  return key + '-' + VERSION
}

export function setLocalData(key, data) {
  localStorage.setItem(makeKey(key), JSON.stringify(data))
}

export function getLocalData(key) {
  const data = localStorage.getItem(makeKey(key))
  if (data == null) return null
  try {
    return JSON.parse(data)
  } catch (err) {
    return null
  }
}

export function localPutIn([key, ...path], data) {}

export function isBlurOutside(e) {
  return e.relatedTarget == null || !e.currentTarget.contains(e.relatedTarget)
}

export function isOffscreen(elem) {
  const bounds = elem?.getBoundingClientRect()

  if (!bounds) return false

  return bounds.left < 0 || bounds.right > document.body.clientWidth
}

export function targetValue(e) {
  return e.target.value
}

/*
 * @doctests
 *
 * ```js
 * const qs1 = '?flag=hello&flag=world&look=ma'
 * const qs2 = '?a=foo&a=bar&a=baz'
 * t.is(toggleQueryParam(qs1, 'flag', 'world').toString(), 'flag=hello&look=ma')
 * t.is(toggleQueryParam(qs1, 'look').toString(), 'flag=hello&flag=world')
 * t.is(toggleQueryParam(qs1, 'look', 'ma').toString(), 'flag=hello&flag=world')
 * t.is(toggleQueryParam(qs1, 'look', 'nohands').toString(), 'flag=hello&flag=world&look=ma&look=nohands')
 * t.is(toggleQueryParam(qs2, 'a').toString(), '')
 * t.is(toggleQueryParam(qs2, 'a', 'bar').toString(), 'a=foo&a=baz')
 * t.is(toggleQueryParam(qs2, 'a', 'buzz').toString(), 'a=foo&a=bar&a=baz&a=buzz')
 * ```
 */
export function toggleQueryParam(q, name, value) {
  const searchParam = new URLSearchParams(q)

  if (searchParam.has(name, value)) {
    searchParam.delete(name, value)
  } else {
    searchParam.append(name, value)
  }

  return searchParam
}

/*
 * @doctests
 *
 * ```js
 * const qs1 = '?flag=hello&flag=world&look=ma'
 * const qs2 = '?a=foo&a=bar&a=baz'
 * t.is(removeQueryParams(qs1, 'flag').toString().toString(), 'look=ma')
 * t.is(removeQueryParams(qs1, 'look').toString().toString(), 'flag=hello&flag=world')
 * t.is(removeQueryParams(qs1, ['flag', 'look']).toString(), '')
 * t.is(removeQueryParams(qs2, 'a').toString(), '')
 * t.is(removeQueryParams(qs2, 'x').toString(), 'a=foo&a=bar&a=baz')
 * ```
 */
export function removeQueryParams(q, names) {
  const searchParam = new URLSearchParams(q)
  names = Array.isArray(names) ? names : Array.of(names)
  names.forEach((name) => {
    searchParam.delete(name)
  })
  return searchParam
}

/*
 * @doctests
 *
 * ```js
 * const qs1 = '?a=foo&a=bar&a=baz'
 * const qs2 = '?a=1&b=2'
 * t.is(setQueryParam(qs1, 'a', 'buz').toString(), 'a=buz')
 * t.is(setQueryParam(qs1, 'b', 'bah').toString(), 'a=foo&a=bar&a=baz&b=bah')
 * t.is(setQueryParam(qs1, 'a', '').toString(), '')
 * t.is(setQueryParam(qs2, 'a', '2').toString(), 'a=2&b=2')
 * t.is(setQueryParam(qs2, 'b', '2').toString(), 'a=1&b=2')
 * t.is(setQueryParam(qs2, 'b', '').toString(), 'a=1')
 * ```
 */
export function setQueryParam(q, name, value) {
  const searchParam = new URLSearchParams(q)
  if (value) {
    searchParam.set(name, value)
  } else {
    searchParam.delete(name)
  }
  return searchParam
}

/*
 * @doctests
 *
 * ```js
 * const qs1 = '?a=1&b=2'
 * t.is(appendQueryParams(qs1, [['c', 3], ['d', 4]]).toString(), 'a=1&b=2&c=3&d=4')
 * t.is(appendQueryParams(qs1, [['a', 3], ['b', 4]]).toString(), 'a=1&b=2&a=3&b=4')
 * t.is(appendQueryParams(qs1, [['a', 1], ['a', 1]]).toString(), 'a=1&b=2')
 * ```
 */
export function appendQueryParams(q, keyvals) {
  const searchParam = new URLSearchParams(q)
  keyvals.forEach(([name, value]) => {
    if (!searchParam.has(name, value)) {
      searchParam.append(name, value)
    }
  })
  return searchParam
}

export const freeze = Object.freeze

// borrowed from example at:
//
//   https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze
//
function freezeObj(obj, freezer, maxDepth) {
  for (const name of Reflect.ownKeys(obj)) {
    const value = obj[name]
    if (value && typeof value === 'object') {
      // max maxDepth
      if (maxDepth !== undefined && maxDepth > 0) {
        freezer(value, maxDepth - 1)
      } else {
        Object.freeze(value)
      }
    }
  }
  return Object.freeze(obj)
}

export function shallowFreeze(object) {
  return freezeObj(object, Object.freeze)
}

// dangerous because you can over-do it into things you didn't expect, like
// if window is included, or if you have cross-linked objects(recursive)
export function deepFreeze(object, maxDepth = undefined) {
  return freezeObj(object, deepFreeze, maxDepth)
}

/*
 * @doctests
 *
 * ```js
 * t.falsy(isGoodGqlVariable([, null]))
 * t.falsy(isGoodGqlVariable([,undefined]))
 * t.falsy(isGoodGqlVariable([,'']))
 * t.falsy(isGoodGqlVariable([,'  ']))
 * t.falsy(isGoodGqlVariable([,[]]))
 * t.truthy(isGoodGqlVariable([,'hello']))
 * t.truthy(isGoodGqlVariable([,{}]))
 * t.truthy(isGoodGqlVariable([,new Date()]))
 * t.truthy(isGoodGqlVariable([,34]))
 * t.truthy(isGoodGqlVariable([,0]))
 * ```
 */
export function isGoodGqlVariable([_, v]) {
  if (Array.isArray(v)) return v.length > 0
  if (typeof v === 'number' && isNaN(v)) return false
  if (v == null) return false
  if (typeof v === 'string') return v?.trim()?.length > 0
  return true
}
