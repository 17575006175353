import React from 'react'

// THIS FILE IS AUTO-GENERATED -- DO NOT EDIT THIS FILE

function Footer(props) {
  return (
    <div className="base-navbar w-100 pv5">
      <div className="flex-start flex-column flex-row-l gh4 gv3 pb3">
        <div className="w-100 w-50-l pr5">
          <div className="flex items-start flex-column flex-row-s">
            <img
              className="h2 ph4 ph5-s"
              src="https://cato.digital/assets/img/cato-logo1-light-alpha.svg"
              alt="Cato Digital"
            />
            <div className="ph4 ph0-s mt4 mt0-s">
              <h3 className="mb3">Ready to get started?</h3>
              View available application or storage servers.
              <a
                className="db mt3 b ttu"
                href="https://console.cato.digital/start/cd/"
              >
                Get Started
              </a>
            </div>
          </div>
        </div>
        <div className="w-100 w-50-l mt5 mt0-l ph4 pl0-l pr5-l flex flex-none-l justify-center">
          <div>
            <div className="mb3 flex-start gh3 gh4-l">
              <div className="flex flex-column gv1">
                <a href="https://cato.digital/product/" className="db">
                  Products
                </a>

                <a href="https://cato.digital/product/" className="db plain">
                  Overview
                </a>

                <a href="https://cato.digital/product/app/" className="db plain">
                  App Servers
                </a>

                <a
                  href="https://cato.digital/product/storage/"
                  className="db plain"
                >
                  Storage Servers
                </a>

                <a href="https://cato.digital/product/gpu/" className="db plain">
                  GPU Servers
                </a>
              </div>

              <div className="flex flex-column gv1">
                <a href="https://cato.digital/about/" className="db">
                  About
                </a>

                <a href="https://cato.digital/about/" className="db plain">
                  Team
                </a>

                <a href="https://cato.digital/partners/" className="db plain">
                  Partners
                </a>

                <a href="https://cato.digital/news/" className="db plain">
                  News
                </a>
              </div>

              <div className="flex flex-column gv1">
                <a href="https://cato.digital/support/" className="db">
                  Support
                </a>

                <a href="https://cato.digital/support/" className="db plain">
                  Options
                </a>

                <a href="https://cato.digital/kb/" className="db plain">
                  Knowledgebase
                </a>

                <a
                  href="https://cato.digital<%= @links.discord %>"
                  className="db plain"
                >
                  Chat Server
                </a>

                <a
                  href="https://cato.digital<%= @links.portal %>"
                  className="db plain"
                >
                  Support Portal
                </a>

                <a
                  href="https://cato.digital<%= @links.contact %>"
                  className="db plain"
                >
                  Contact Form
                </a>

                <a href="https://cato.digital/terms/" className="db plain">
                  Terms
                </a>
              </div>

              <div className="flex flex-column gv1">
                <a href="https://console.cato.digital/start/cd/" className="db">
                  Get Started
                </a>

                <a href="https://cato.digital/contact/" className="db plain">
                  Contact
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-center ">
        <div className="f2">
          <span>Cato Digital &trade;, and &copy; Cato Digital, inc</span> | 
          <a href="https://cato.digital/terms/" className="plain">
            Terms
          </a>
           | 
          <a href="https://cato.digital/terms/privacy" className="plain">
            Privacy
          </a>
        </div>
        <div className="ml3">
          <a
            className="ph2 f5"
            href="https://twitter.com/CatoDigitalInc"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-twitter"></i>
          </a>
          <a
            className="ph2 f5"
            href="https://www.linkedin.com/company/cato"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-linkedin"></i>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer
