import { debounce } from 'lodash'

import { handleOneResponse } from 'utils/response'

function getCurrent(user, group, token) {
  return user.dataTypes[group] || { value: { [token]: '' } }
}

export function saveData(opts, value, g, b) {
  const { group, token, user } = opts
  const current = getCurrent(user, group, token)
  let data = { ...(current.value || {}), [token]: value }
  value = JSON.stringify(data)
  user.onSave(
    {
      ...opts,
      component: 'data',
      componentId: false,
      token: 'value',
      cmeta: { type: group, id: current.id }
    },
    value,
    g,
    b
  )
}

export function saveToggle(user, type, token, value) {
  const cur = user.dataTypes[type] || { value: {} }
  const curVal = cur.value[token]
  if (curVal === undefined || curVal !== value) {
    let updated = { ...(cur.value || {}), [token]: value }
    // our search checks for value is null to determine something is off,
    // so delete it instead of setting it to false
    if (value === false) {
      delete updated[token]
    }
    value = JSON.stringify(updated)
    user.onSave(
      {
        component: 'data',
        componentId: false,
        token: 'value',
        cmeta: { type, id: cur.id }
      },
      value
    )
  }
}

export function updateSettings(changeUser, userId, settings) {
  return changeUser({
    variables: {
      id: userId,
      user: {
        settings: JSON.stringify(settings)
      }
    }
  }).then(handleOneResponse('data', 'updateUser'))
}

export const updateSettingsDebounced = debounce(updateSettings, 750)
